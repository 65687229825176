<template>
  <div class="home">
    <!--    <img src="../../../public/images/veecam/otherSoftware/其他软件下载.png" lazy width="100%" height="100%">-->
    <div class="bj">
      <div class="font">其他软件</div>
      <div class="box">
        <div class="box_1">
          <img src="../../../public/images/veecam/otherSoftware/2.jpg">
          <p>查找器</p>
          <div class="box_font">Windows:lP Camera Finder</div>
          <img @click="download" class="xz" src="../../../public/images/veecam/otherSoftware/xz.png">
        </div>
        <div class="box_1">
          <img style="margin-top: 20px" src="../../../public/images/veecam/otherSoftware/windows.png" />
          <p>Windows版</p>
          <div class="box_font">在Windows电脑上观看实时画面，抓拍、录像实时图像、提醒、设置等功能。</div>
          <img @click="downWindows" style="margin: 12px 0px 0px 150px;cursor: pointer" src="../../../public/images/veecam/otherSoftware/xz.png">
        </div>
        <div class="box_1">
          <img src="../../../public/images/veecam/otherSoftware/3.jpg">
          <p>存储卡录像回放工具</p>
          <div class="box_font">用于播放TF卡中的录像文件。</div>
          <img @click="downloads" class="xz" src="../../../public/images/veecam/otherSoftware/xz.png">
        </div>
      </div>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "otherSoftware",
  components: {
    elMenu, foot, navigation
  },
  methods: {
    download(){
      window.open('https://doraemon.camera666.com/finder.zip');
    },
    downloads(){
      window.open('https://doraemon-hangzhou.camera666.com/VideoPlayer_1620313091.zip');
    },
    downWindows(){
      window.open('https://doraemon.camera666.com/SuperIPCam-OKAM.zip');
    }
  }
}
</script>

<style scoped>
.bj {
  width: 100%;
  height: 100%;
  background: url("../../../public/images/veecam/otherSoftware/bj.jpg") no-repeat;
  background-size: 100% 100%;
}

.font {
  text-align: center;
  font-size: 32px;
  padding-top: 10%;
}

.box {
  margin-top: 120px;
  display: flex;
  justify-content: space-evenly;
}

.box_1 {
  text-align: center;
  width: 270px;
  height: 370px;
  background-color: white;
  border-radius: 15px;
}

.box_font {
  width: 80%;
  margin: auto;
  padding-top: 20px;
}

.xz {
  margin: 50px 0px 0px 150px;
}
.xz:hover{
  cursor:pointer;
}
</style>